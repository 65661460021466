import React, { useState } from 'react';
import './RevisionScheduler.css'

export default function RevisionSchedulerButtonsAndTextBox({OnRevisionScheduleEvent, revisionSchedule})
{
    const initialOffset = revisionSchedule ?  revisionSchedule.PreviousOffset : 0;
    const [selectedOffset, setSelectedOffset] = useState(null);
    function OnButtonClick(offset)
    {
        setSelectedOffset(prevOffset => {
            var nextRevisionDate = new Date();
            if(prevOffset === offset)
            {
                // revert
                let revertdate = new Date(revisionSchedule.PreviousDateOfRevision);
                revertdate.setDate(revertdate.getDate() + initialOffset);
                OnRevisionScheduleEvent(revertdate, initialOffset, true);
                return null;
            }
            nextRevisionDate.setDate(new Date().getDate() + offset);
            OnRevisionScheduleEvent(nextRevisionDate, offset, false);
            return offset;
        })
    }
    function onCustomInput(Offset)
    {
        let offset = parseInt(Offset);
        if(isNaN(offset) || offset<=0)
            offset=0;
        // At least once a year you should see the topic
        if(offset>=365)
            offset=365
        var nextRevisionDate = new Date();
        nextRevisionDate.setDate(new Date().getDate() + offset)
        OnRevisionScheduleEvent(nextRevisionDate, offset);
        setSelectedOffset(null);
    }
    const buttonValues = [1, 3, 7, 14, 30, 60, 90];

    return(
        <div className="RevisionScheduler">
            <label>Schedule Revision in Days:</label>
            <div class="RevisionSchedulerButtons">
            {buttonValues.map(value => (
                    <button 
                        key={value} 
                        onClick={() => OnButtonClick(value)}
                        className={'RevisionSchedulerButtonsbutton' + (value === selectedOffset ?  ' HighlightRevisionSchedulerButton' : '') + (value === initialOffset ? ' BoldRevisionSchedulerButton' : '') }
                    >
                        {value}
                    </button>
                ))}
                <div class="RevisionSchedulerTextBox" onChange={(e) => onCustomInput(e.target.value)}>
                    <input type="number" min="1" max="365" />
                </div>
            </div>
            {revisionSchedule && parseInt(revisionSchedule.RevisionCounter)>1 && <div className='lastRevision'>( Revised for {(parseInt(revisionSchedule.RevisionCounter) - 1).toString()} time(s) and last revised on {new Date (revisionSchedule.PreviousDateOfRevision).toLocaleDateString('en-GB')})</div>}
            {revisionSchedule && revisionSchedule.NextRevisionDate && <div className='lastRevision'>( Revision scheduled for {new Date (revisionSchedule.NextRevisionDate).toLocaleDateString('en-GB')})</div>}
            <div className='gradLineAndLabel'>
                <div className="gradLine"></div>
                <div className='gradlinelabels'>
                    <div className="RevisionBad">Beginner</div>
                    <div className="RevisionGood">Expert</div>
                </div>
            </div>
      </div>
    );

}