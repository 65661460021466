export const gateway= process.env.REACT_APP_GATEWAY;
export const localhost = process.env.REACT_APP_URL;
export const safariFixcacheBuster = '?nocache=' + new Date().getTime();
export const LoginUrl = gateway + '/login' + safariFixcacheBuster;
export const RegisterUrl = gateway + '/register' + safariFixcacheBuster;
export const ResetPasswordUrl = gateway + '/resetpassword' + safariFixcacheBuster;
export const dataUrl = gateway + '/data' + safariFixcacheBuster;
export const paymentUrl = gateway + '/payment' + safariFixcacheBuster;
export const paymentverificationUrl = gateway+'/verifypayment' + safariFixcacheBuster;
export const ApiKey = process.env.REACT_APP_APIKEY;
export const razorpayURL = 'https://checkout.razorpay.com/v1/checkout.js';  
export const RazorpayAPIkey = process.env.REACT_APP_RAZORPAYAPIKEY;
export const stylesFS = process.env.REACT_APP_STYLESURL;
export const ProductsOffered = ['GS1', 'GS2', 'GS3', 'GS4', 'Essay']
// alias for about Us
export const Peptalk =`
Welcome to the Future of UPSC Preparation - CIVILSCODE

<b>Embark on a Revolutionary Journey</b>

In a world where UPSC exam preparation can feel like navigating a labyrinth, CIVILSCODE emerges as a beacon of innovation and simplicity. Born from a vision to transform the traditional study grind into an empowered learning adventure, we've set out to offer A-Z solutions that redefine success for civil services aspirants.

<b>What We Offer - The CIVILSCODE Arsenal</b>

Dive into our meticulously crafted 'NCERT Simplified' series and explore our '360-Degree Notes'—your compass in the vast ocean of the UPSC syllabus. But we didn't stop there. Our signature triumph is the pioneering 'Content Personalization Technology', a tool that allows you to mold your study material into what suits you best, right on our platform.

<b>Our Edge - The CIVILSCODE Difference</b>

Standing tall as the sole beacon in India for smart, simplified notes complemented by on-site content personalization, CIVILSCODE is not just a study platform; it's a revolution. With technological prowess unmatched by any, we are setting new standards for what it means to prepare for the UPSC exams.  We ensure that your notes will be with you always, accessible anywhere. This means that your preparation knows no bounds—study at your pace, on your terms, whether you're at home, in a café, or on the move. 

<b>Our Creed - The Essence of CIVILSCODE</b>

At our core, we believe in the powerful synergy between 'Simplicity' and 'Technology'. This philosophy drives us to equip UPSC aspirants with not just knowledge, but a streamlined, efficient approach to conquering their exams. We're not just preparing students; we're shaping the future civil servants of India.

<b>Our Community - Who We Serve</b>

CIVILSCODE is the sanctuary for every UPSC aspirant—those embarking on their first step and the veterans aiming for the zenith. Here, every dreamer finds their path illuminated, every strategist finds their plan refined, and every aspirant finds their potential unleashed. We extend our reach to aspirants whether they are in the hinterlands or the frontiers of the country, in bustling cities or remote villages, ensuring all have complete preparation at their fingertips. This inclusivity underscores our commitment to democratize UPSC preparation, breaking geographical barriers and bringing top-tier resources to every corner of India. With CIVILSCODE, distance and location cease to be obstacles in your quest for civil service; instead, they become testaments to the boundless possibilities that await when preparation meets opportunity.

<b>Join the CIVILSCODE Odyssey</b>

Step into CIVILSCODE, where the journey to becoming a civil servant is transformed into an exhilarating quest. With us, preparation transcends the mundane, becoming an engaging, enlightening experience that propels you towards your goal. Let's navigate this journey together, where simplicity and technology craft the future of UPSC preparation.`
export const WhatWeOffer = `
At CIVILSCODE, we are committed to providing UPSC aspirants with a comprehensive suite of tools and resources designed to transform the way you prepare for one of the most challenging exams in India. Our offerings are tailored to meet the needs of every aspirant, ensuring a personalized, effective, and engaging learning experience.

<b>NCERT Simplified</b>

Dive into the essence of the UPSC syllabus with our NCERT Simplified series. We've taken the core NCERT textbooks and distilled them into simple, detailed, pointwise explanations. Our approach makes understanding complex concepts easier than ever, ensuring you grasp the foundational knowledge necessary for success in the UPSC exams. This series is your first step towards mastering the basics, presented in a clear and concise manner.

<b>360-Degree Notes</b>

Our signature offering, the 360-Degree Notes, represents the pinnacle of our content creation efforts. These notes are meticulously crafted by our team of experts, covering 100% of the UPSC syllabus in a unique, comprehensive manner. Designed to provide a holistic view of each subject, our 360-Degree Notes ensure that no stone is left unturned in your preparation. With every topic covered from multiple angles, these notes are your ultimate guide to understanding and retaining critical information for the exam.

<b>Learning</b>

To complement our content, we offer an array of innovative learning tools designed to enhance your study experience:

	<b>Content Personalization</b> <div class="indenttext">Tailor your study material to fit your needs with options to highlight and organize key information, making it easier to focus on what's important to you.</div>
	<b>Revision Notes Maker</b><div class="indenttext">Create customized revision notes effortlessly, enabling you to consolidate your learning and reinforce your understanding of complex topics.
	</div>
These tools are designed to make your preparation more efficient and effective, providing you with the flexibility to study in a way that best suits your learning style.

<b>Join Us for a Transformative Learning Experience</b>

At CIVILSCODE, we believe in empowering UPSC aspirants with the knowledge, tools, and confidence needed to achieve their dreams. Our comprehensive offerings are just the beginning. Join us on this journey, and experience the difference that comes with having a world-class preparation platform at your fingertips.`
export const OurPhilosophy = `
We're embarked on an extraordinary mission to transform UPSC preparation from its very core, aspiring to elevate it beyond the traditional benchmarks. Each innovation we unveil, each success story of our aspirants, signifies not merely an evolution but a monumental stride towards a luminous future. This quest transcends the boundaries of mere exam preparation; it's a call to inspire profound change, impacting one aspirant at a time. By integrating cutting-edge technology, we aim to streamline the preparation process, ensuring a smoother, more efficient journey for you. Together, let's step into this transformative adventure, aligning your aspirations with our unwavering dedication to redefine what it means to excel. Join us as we forge ahead, championing a new era of UPSC preparation that is not only empowered by technology but also imbued with the spirit of innovation and the promise of a brighter tomorrow.`
export const ContactUsBody = `
<div class="ContactUsContent">
<svg id="WhatsAppSvg" height="20px" width="20px" version="1.1" 
	 viewBox="0 0 308 308" xml:space="preserve">
<g id="XMLID_468_">
	<path id="XMLID_469_" d="M227.904,176.981c-0.6-0.288-23.054-11.345-27.044-12.781c-1.629-0.585-3.374-1.156-5.23-1.156
		c-3.032,0-5.579,1.511-7.563,4.479c-2.243,3.334-9.033,11.271-11.131,13.642c-0.274,0.313-0.648,0.687-0.872,0.687
		c-0.201,0-3.676-1.431-4.728-1.888c-24.087-10.463-42.37-35.624-44.877-39.867c-0.358-0.61-0.373-0.887-0.376-0.887
		c0.088-0.323,0.898-1.135,1.316-1.554c1.223-1.21,2.548-2.805,3.83-4.348c0.607-0.731,1.215-1.463,1.812-2.153
		c1.86-2.164,2.688-3.844,3.648-5.79l0.503-1.011c2.344-4.657,0.342-8.587-0.305-9.856c-0.531-1.062-10.012-23.944-11.02-26.348
		c-2.424-5.801-5.627-8.502-10.078-8.502c-0.413,0,0,0-1.732,0.073c-2.109,0.089-13.594,1.601-18.672,4.802
		c-5.385,3.395-14.495,14.217-14.495,33.249c0,17.129,10.87,33.302,15.537,39.453c0.116,0.155,0.329,0.47,0.638,0.922
		c17.873,26.102,40.154,45.446,62.741,54.469c21.745,8.686,32.042,9.69,37.896,9.69c0.001,0,0.001,0,0.001,0
		c2.46,0,4.429-0.193,6.166-0.364l1.102-0.105c7.512-0.666,24.02-9.22,27.775-19.655c2.958-8.219,3.738-17.199,1.77-20.458
		C233.168,179.508,230.845,178.393,227.904,176.981z"/>
	<path id="XMLID_470_" d="M156.734,0C73.318,0,5.454,67.354,5.454,150.143c0,26.777,7.166,52.988,20.741,75.928L0.212,302.716
		c-0.484,1.429-0.124,3.009,0.933,4.085C1.908,307.58,2.943,308,4,308c0.405,0,0.813-0.061,1.211-0.188l79.92-25.396
		c21.87,11.685,46.588,17.853,71.604,17.853C240.143,300.27,308,232.923,308,150.143C308,67.354,240.143,0,156.734,0z
		 M156.734,268.994c-23.539,0-46.338-6.797-65.936-19.657c-0.659-0.433-1.424-0.655-2.194-0.655c-0.407,0-0.815,0.062-1.212,0.188
		l-40.035,12.726l12.924-38.129c0.418-1.234,0.209-2.595-0.561-3.647c-14.924-20.392-22.813-44.485-22.813-69.677
		c0-65.543,53.754-118.867,119.826-118.867c66.064,0,119.812,53.324,119.812,118.867
		C276.546,215.678,222.799,268.994,156.734,268.994z"/>
</g>
</svg>
<a href="https://wa.me/+917995467223" id="Hyperlinks" target="_blank">+91-7995467223</a>
<address>
   Civilsphere Educational Services Pvt Ltd, <br>
   602, VASANTHA HOMES, <br>
   AYYAPPA SOCIETY, MADHAPUR, <br>
   HYDERABAD, 500084
</address>
</div>
`
export const PrivacyPolicyBody = `
At CIVILSCODE, your privacy is paramount. We are committed to protecting the personal information of our users, recognizing the trust you place in us as you navigate the journey of UPSC preparation on our platform. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you visit our website and use our services.

<b>Information We Collect </b>

We collect information that helps us deliver a personalized and efficient learning experience. This includes, but is not limited to
	Personal Identification Information: Name, email, phone number, and other details.
	Educational Data: Study preferences, quiz results, and progress tracking.
	Technical Data: IP addresses, browser types, and other technology on the devices you use to access our platform.

<b>How We Use Your Information:</b>

The information we collect is used to

	Provide, operate, and maintain our services.
	Enhance, personalize, and expand our website.
	Understand and analyze how you use our platform.
	Develop new products, services, features, and functionality.
	Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes.
	Send you emails and updates about your progress and new offerings.

<b>Sharing Your Information</b>

We do not sell, trade, or otherwise transfer to outside parties your Personally Identifiable Information unless we provide users with advance notice. This does not include website hosting partners and other parties who assist us in operating our website, conducting our business, or serving our users, so long as those parties agree to keep this information confidential. We may also release information when its release is appropriate to comply with the law, enforce our site policies, or protect ours or others' rights, property or safety.

In compliance with the Information Technology Act, 2000 (IT Act), and the Information Technology (Intermediary Guidelines and Digital Media Ethics Code) Rules, 2021, we ensure that our data handling practices respect your privacy rights and adhere to the regulatory framework.

<b>Data Security</b>

We implement a variety of security measures to maintain the safety of your personal information. Your personal information is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems, and are required to keep the information confidential.\n

<b>Your Rights</b>

Under the IT Act and the Information Technology (Intermediary Guidelines and Digital Media Ethics Code) Rules, 2021, you have the right to access, correct, or delete your personal data. For any requests related to your data rights, please contact us at +917995467223

<b>Changes to Our Privacy Policy</b>

We reserve the right to update this privacy policy at any time. We will notify you of any changes by posting the new privacy policy on this page. You are advised to review this Privacy Policy periodically for any changes.
`
export const TermsAndCondtions = `Welcome to CIVILSCODE

Thank you for choosing CIVILSCODE for your UPSC civil services examination preparation. By accessing our website and using our services, you agree to be bound by the following terms and conditions (Terms). Please read them carefully.

<b>Use of Our Services</b>

CIVILSCODE provides a variety of educational resources, including but not limited to, NCERT Simplified notes, 360-Degree Notes, and personalized learning tools. These services are intended for personal, non-commercial use only.

<b>Account Registration</b>

You may be required to create an account to access certain features. You agree to provide accurate, current, and complete information during the registration process and to update such information to keep it accurate, current, and complete.

<b>Content Use</b>

The content provided on CIVILSCODE, including text, graphics, logos, and software, is the property of CIVILSCODE or its content suppliers and protected by Indian and international copyright laws.

<b>User Conduct</b>

You agree not to use the website or its content for any unlawful purpose.
You agree not to infringe on the intellectual property rights of CIVILSCODE or others.
You agree not to upload, post, email, or otherwise transmit any content that is unlawful, harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libelous, invasive of another's privacy, hateful, or racially, ethnically, or otherwise objectionable.
You agree not to share credentials with other users, also agree to have only one session at any time.
Breach in user conduct may not only lead to termination of subscription but also lead to law suits. 

<b>Subscription Fees and Payments</b>

Some parts of the Service are billed on a subscription basis. You will be billed in advance on a recurring and periodic basis (Billing Cycle). All successful payments to CIVILSCODE are not refundable.

<b>Changes to Terms and Conditions</b>

CIVILSCODE reserves the right, at its sole discretion, to modify or replace these Terms at any time. We will provide notice of any changes by posting the new Terms on the website. Your continued use of the Service after such changes constitutes acceptance of the new Terms.

<b>Disclaimer of Warranties and Limitation of Liability</b>

The services and content of CIVILSCODE are provided on an 'as is' and 'as available' basis without any warranties of any kind, either express or implied.
CIVILSCODE does not warrant that the service will be uninterrupted, timely, secure, or error-free.

<b>Governing Laws</b>

These Terms shall be governed by and construed in accordance with the laws of India, providing a clear legal framework for both CIVILSCODE and its users. In navigating the complexities of digital education and online services, we adhere to the following key statutes and regulations, ensuring our operations are not only innovative but also legally compliant:

Information Technology Act, 2000 (IT Act): As the primary law in India dealing with cybercrime and electronic commerce, the IT Act outlines the legal obligations concerning digital communications and transactions. This includes provisions for the protection of personal data collected and processed by online platforms like CIVILSCODE.

Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules, 2011: These rules further define the standards for protecting sensitive personal data, emphasizing the importance of consent, security practices, and the user's rights in relation to their personal information.

Information Technology (Intermediary Guidelines and Digital Media Ethics Code) Rules, 2021: These guidelines establish the responsibilities of intermediaries, including edtech platforms, in moderating and managing user-generated content, ensuring a safe and respectful online environment.

Consumer Protection Act, 2019: This act provides for the protection of consumer rights, including those of students and learners using educational services and products. It addresses unfair trade practices, misleading advertisements, and provides for consumer redressal mechanisms.

Copyright Act, 1957: Protecting the intellectual property rights of the content creators and the educational materials provided on CIVILSCODE, this law ensures that original works are safeguarded against unauthorized use and distribution.

By agreeing to these Terms, users affirm their understanding and compliance with the legal provisions under these statutes. Any disputes arising in connection with the use of CIVILSCODE's services will be resolved through arbitration or in the competent courts within India, adhering to the principles and procedures outlined in the aforementioned laws.

<b>Refund & Cancellation Policy:</b>

1.Transaction Responsibility:
All monetary transactions made by the user through the site are processed via the payment gateway partner of CivilSphere. CivilSphere is not responsible for any discrepancies arising from the payment process. It is the user’s responsibility to ensure that payment details are entered accurately.
2.Refund Eligibility:
CivilSphere reserves the right to consider and review refund requests only if the amount paid by the user exceeds the fee for the number of applications submitted. Refunds will not be granted for payments made in excess due to user error unless there is clear evidence of overpayment directly attributable to a system malfunction.
3.Payment Verification:
Users are required to keep a record of the Transaction ID and receipt number provided by the payment gateway. These details may be needed to furnish additional information or track the payment status of an application. Failure to provide these details may delay or prevent the resolution of payment issues.
4.Non-Refundable Payments:
Payments for services are generally non-refundable. Exceptions may be made at the discretion of CivilSphere, based on specific circumstances, but are not guaranteed.
5.Service Disruption:
In the unlikely event of a technical error or service disruption during the payment process that results in an incorrect transaction, CivilSphere will work with the payment gateway to investigate and resolve the issue. Refunds or payment adjustments may be considered if it is determined that the error was on the part of the system.
6.Cancellation Policy:
Once a registration has been done and payment has been made, cancellations will not be entertained. The registration will be considered final and binding. Users should review their application details thoroughly before completing the payment process.
7.Legal Liability:
CivilSphere is not liable for any legal claims arising from the use of third-party payment gateways. All users are advised to read and understand the payment terms and conditions before initiating any transactions.

<b>Contact Us</b>

If you have any questions about these Terms, please contact us at +917995467223`

export const breakMessages = [
    "You've been scrolling so long; your coffee thinks you ghosted it! Stretch time?",
"If you stare any longer, your screen might file a restraining order! Stand up and stretch?",
"Your back called. It's feeling left out. How about a little stretch?",
"Warning: Your posture is currently imitating the Leaning Tower of Pisa. Time to stand up straight!",
"Quick question: Can screens get tan? Because you've been throwing shade at yours for hours! Time for a break!",
"Roses are red, violets are blue, your screen misses your face...just kidding! Stand and renew!",
"Did you know that if you stretch now, your laptop gets 5 minutes of extra battery life? Okay, maybe not, but you should stretch anyway!",
"Your screen just whispered that it’s tired of your face. Stretching time?",
"Eyes need a break too! Let them roam somewhere other than this glowing box.",
"Remember that outside place? The one without a ceiling? Maybe peek out there after a quick stretch.",
"If your laptop had feelings, it'd probably want you to look away for a minute. Don't hurt its feelings!",
"You’ve been online so long, even your keyboard is saying 'BRB'. Time to stretch?",
"Alert! Your chair just tagged you in a 'missing person' report. Stand up and check-in!",
"🚨Breaking News🚨: Your chair is starting a petition for more breaks. Don't disappoint!",
"Fun Fact: Your screen thinks you have the most hypnotic eyes... because you never look away. Blink. Stretch. Repeat!",
"If sitting was a sport, you'd be an Olympian by now. How about training in 'stretching'?",
"Your Wi-Fi just called; it wants a 5-minute break too! Stand up and reboot (yourself).",
"WANTED: One standing human. REWARD: Feeling refreshed! Are you up for the challenge?",
"If your back had a Twitter, it'd tweet #NeedABreak. Don't make it go viral!",
"Your mouse has started to think it's a pet. Maybe pet an actual animal instead, after a good stretch?",
"Your coffee cup is jealous of the attention you're giving your screen. Sip, stand, and stretch!",
"Are those chair-shaped imprints on your backside? Time for a stand-up comedy break (with just the standing)!",
"Your chair's been carrying the weight of your genius for hours. Give it a brief holiday!",
"You’ve been stationary longer than that stapler on your desk. Pop up and stretch!",
"There's a rumor that your plants do a dance when you stand up. Time to catch them in the act?",
"Your screen's brightness doesn’t compare to the sunshine outside. Peek out and stretch those limbs!",
"The cookies in the jar think you've forgotten about them. Maybe stand, stretch, and snag a snack?",
"Legends say if you stand and stretch, your laptop's loading speed increases by 10%. Worth a try, right?",
"📚Hey Bookworm! The pages miss the sound of your flipping. Stretch now, so you can turn them longer!",
"If you've been studying this long, you're probably on your way to becoming the subject expert. But even experts need a stretch!",
"Your notebook just whispered it’s starting to feel heavy with all that knowledge. Lighten the load with a quick break!",
"Think of this break as a recess for your brain. Tag! You're it!",
"Alert! Your highlighter is trying to run away because of overuse. Chase it down after a quick stretch!",
"Even Newton discovered gravity when he wasn’t studying. Who knows what you'll discover with a short break?",
"Did you know? Your brain absorbs info better after a breather! Stretch now for that A+ later!",
"Pop quiz! What’s the best way to refresh during study time? A) Stretch B) Stretch C) All of the above!",
"Your textbooks are having a secret meeting about how awesome you are. Give them a moment, and take a break!",
"You’ve been hitting the books so hard; they might just start hitting back! Time for some peace talks (and a stretch)!",
"Imagine if your brain had a battery level indicator. Don't wait for the 1% warning – recharge with a stretch!",
"Brains are like sponges. Squeeze out the stress with a break, so there’s more room to soak up knowledge!",
"Your pencil’s almost worn out from all the note-taking. Let it catch its breath while you stretch those legs!",
"The library called. They said even their most ancient books advocate for study breaks. Ancient wisdom, eh?",
"Studying without breaks is like trying to fill a cup that’s already full. Empty it out with a little shake and dance!",
"Your brain's RAM is getting full. Time to clear some cache with a stretch!",
"Hey Scholar! Your brain’s been flexing its muscles. How about the rest of you?",
"Legend says every time you stretch, a forgotten fact falls back into place. Stretch for that elusive answer!",
"I spy with my little eye... a hardworking student in need of a break. Did you guess 'you'? Stretch it out!",
"Just a reminder: Even Einstein had to comb his wild hair occasionally. Untangle your thoughts with a break!",
"Your notes are starting to look like ancient hieroglyphics. Decode them better after a quick stretch!",
"Achievement Unlocked: Marathon Studier! Claim your reward: a 5-minute break.",
"If knowledge is power, you're practically a superhero by now. Even superheroes stretch before saving the day!",
"You've been so focused, even a ninja couldn't distract you. Show off some of those ninja moves with a quick stretch!",
"🚀Your brain's reaching stratosphere levels of knowledge! Come back to Earth for a breather.",
"Your pen's thinking of going on strike due to overtime. Negotiate with a break!",
"If you've been studying any harder, we'd have to start calling you 'Professor'. Even professors need tea breaks, you know!",
"You're on fire today! But don't burn out. Stop, drop, and roll... or at least stretch.",
"Staring contest with your book? It's a tough opponent, but I bet it can't beat you at stretching!",
"Warning! Overheated brain! Cool down with a stretch and maybe an ice-cold drink.",
"🌱Think of yourself as a plant. Too much time indoors, without moving, isn't good. Stretch and get some sunlight!",
"Fact: Your chair misses its solo time. Grant its wish for a few minutes. Up you go!",
"Remember the last time you saw the sky? No? Let's fix that! Peek outside and stretch.",
"You + Coffee Break = A more brilliant you. Why argue with math?",
"Curiosity break: Check how many steps you can take around the room in one minute. Ready, set, go!",
"Psst... Your future self sent a message. It says, 'Thanks for that stretch break!'",
"Trivia time: Did you know standing up and stretching can spark joy? Marie Kondo would approve!",
"Hey, Star Student! Don't forget to twinkle – which obviously requires some stretching!",
"As the wise old turtle said, 'Slow and steady wins the race.' But even he took stretch breaks. Just saying.",
"Imagine there’s a secret society of stretchers. Want an invite? Stand up!",
"Your brain’s bandwidth is reaching its limit. Free up some space – stand, shake, refresh!",
"Feeling like you’re in a study loop? Ctrl + Alt + Stretch to break free!",
"How many stretches does it take to reach the center of relaxation? Only one way to find out!",
"Your books are plotting something... probably about how awesome you are. Overhear their whispers during a quick break.",
"A wild break appears! It’s super effective. Take 5 and return to battle, Pokémon Master!",
"Hydration Alert! Your body's not a cactus. Sip some H2-Oh yeah!",
"Fact: Stretching might make you taller... or at least feel like it. Why risk missing out?",
"Remember the sun? Big, bright, warm thing in the sky? Maybe say 'hello' and soak up some Vitamin D-light!",
"Your muscles are plotting a rebellion. Keep the peace by granting them a stretch treaty!",
"Legends say the elixir of life is water. Why not take a sip? Immortality might just be a glass away!",
"The floor is lava! Stand up... and while you're at it, stretch those limbs to stay safe.",
"Do a sun salutation. Not the yoga move, just wave at the sun. It's been a while!",
"Your water bottle called. It feels neglected. Quench its thirst for attention!",
"Wanted: A stretched, hydrated, sun-kissed student. Reward: Feeling fabulous!",
"Fun challenge: Can you touch your toes and sip water simultaneously? Probably not, but worth a shot!",
"If you stretch now, your future self will thank you. And possibly buy you a cookie.",
"Your brain’s been sweating with all this studying! Rehydrate it with a water break.",
"Couch potatoes grow in the shade. But you? You're a sunflower! Time to soak up some sun.",
"You've earned a 1-minute vacation! Destination: Hydration Nation with a layover in Stretchville.",
"Dance break! Best paired with a sip of water and a dash of sunshine.",
"Hydrate before you desiccate. No one wants to study with a mummy!",
"Remember the sun? It's that big fireball that hasn’t burned out yet. Maybe catch some rays before it changes its mind.",
"Stretch now, or the only flexibility you'll have is in your procrastination skills.",
"The afterlife reportedly lacks Wi-Fi. Soak up some sun now; it's a no-tan zone on the other side.",
"Life’s short, and those water breaks? Even shorter. Do the math.",
"Your water bottle’s been staring, looking half-empty or half-full. Either way, it’s a philosophical thirst trap.",
"Newsflash: Chairs report feeling smothered in relationships. Stand up and give yours some space!",
"Join the elite 'I Stretched Today Club'. Exclusive benefits include not turning into the Hunchback of Notre-Dame.",
"Zombies hate fast-moving, hydrated humans. Stretch, drink, and prep for the apocalypse.",
"The sun's shining, which, in cosmic terms, is a rare event. Absorb some photons before it’s lights out!",
"Sure, the shadows are comfortable, but too much and you risk becoming a vampire. Sunshine, anyone?",
"Water: The OG life hack. Drink up before life decides to throw an unexpected error message.",
"You don't want your gravestone to read: 'Never Stretched, Always Stressed', right? Time for a break.",
"Life's not always a beach, but you can still sip like you're on one. Water break!",
"Gravity’s got a love affair with our bodies, but sometimes you just gotta stand up and say 'It’s not you, it’s me'.",
"**Star Wars**: May the Force (of hydration) be with you. And remember, stretch, or stretch not. There is no try.",
"**The Lion King**: It’s the circle of life – study, stretch, hydrate, and sun. Hakuna Matata!",
"**The Godfather**: Here's an offer you can't refuse: A glass of water and a five-minute stretch.",
"**Jurassic Park**: Life, uh, finds a way... especially when you remember to hydrate and bask in some sunshine.",
"**Terminator**: Be back... after a quick water break and some stretching. Hasta la vista, student!",
"**Titanic**: Stretch out your arms and pretend you're flying at the front of the ship. Just don’t hit an iceberg (or the bookshelf).",
"**Iron Man**: \"Even Stark's suit needs recharging. Stretch out and refuel with some high-quality H2O!\"",
"**Captain America**: \"Do you want super-soldier strength? Hydration might not give you that, but it's a start!\"",
"**Thor**: \"Even the God of Thunder needs his stretches. Lift your Mjölnir, or, you know, a water bottle.\"",
"**Black Widow**: \"A spy's most significant weapon? Peak physical condition. Time for a stealthy stretch and covert hydration.\"",
"**Doctor Strange**: \"Bend reality, but first, bend those limbs. And don't forget the Elixir of Life - water.\"",
"**Spider-Man**: \"With great power comes great responsibility. Responsibly take a hydration break and swing into a stretch!\"",
"**Superman**: \"The sun gives Superman his strength. Soak in some rays, but don't forget your kryptonite – dehydration.\"",
"**The Dark Knight**: \"Why do we sit, Master Wayne? So, we can learn to pick ourselves up... and stretch. Also, Gotham needs you hydrated.\"",
"**Interstellar**: \"Time dilation's a thing. You think you've been studying for 5 minutes, but it's been hours! Better stretch those spacetime legs and hydrate.\"",
"**Star Wars**: \"The Force will be with you, always... especially after a good stretch and some hydration.\"",
"**Yoda**: \"Stretch, you must. Hydrate, you should. Wise decisions, they are.\"",
"BREAK ALERT: Aliens just landed and demanded to see the Earth's 'most flexible beings.' Quick, stretch out and grab some water. You might be humanity's last hope!",
"Your wand is signaling! Time for the spell: ‘Hydratus Stretchiosa!’ Transform into a well-hydrated, limber wizard.",
"And here, ladies and gentlemen, we have the rare 'Studious Studentus' in its natural habitat. Observe as it stretches majestically and sips water with unmatched elegance.",
"In the wild plains of Studyland, watch as the student emerges from its hunch, elongating its spine and sipping from the watering hole, ensuring survival in this challenging ecosystem.",
"Article H2O - Right to Refreshment. Every student has the right to stretch and hydrate. It's constitutionally guaranteed!",
"BREAKING: The Cabinet has just passed a resolution. All UPSC aspirants must take a break, stretch, and hydrate!",
"The house is now in session. All members in favor of a stretch and hydration break, say 'Aye'!",
"Even the great Ashoka took breaks during his reign. Emulate the greats, stretch, and sip some water.",
"From the Himalayas to the Indian Ocean, India stretches beautifully. Time for you to do the same!",
"According to the latest census, 99.9% of toppers believed in the magic of stretching and staying hydrated. The other 0.1% were too busy stretching to answer.",
"Just like the Indus Water Treaty, let's have a 'Study Water Strategy'. Ensure a fair distribution of water to all parts of your body!",
"The new Fiscal Policy suggests investing in liquid assets (your water bottle) and expanding flexibility bonds (your limbs). Time for dividends!",
"BREAKING: A new bill has been passed in the 'Study Sabha'—'The Right to Stretch & Hydrate Act, 2023'. Time to enact it!",
"Stock Market Update: Shares in 'Stretch & Sip Ltd.' are soaring! Experts recommend immediate investment for guaranteed returns.",
"Green Revolution 2.0: Introducing 'Hydration Crops' and 'Stretchy Fields'. Increase your yield by taking a break now!",
"UNESCO declares 'Stretching and Hydrating during UPSC prep' as an Intangible Cultural Heritage. Time to honor our legacy!"
]


export function HighlightSvg(props) {
	return(
		<svg  id="HighlightSvg" viewBox="0 -960 960 960" {...props}><path d="m544-412.308-104-104-195.385 195.385Q237.692-314 237.692-304q0 10 6.923 16.923l70.154 70.154Q321.692-210 331.692-210q10 0 16.923-6.923L544-412.308Zm-75.461-132.538 104 104L770-638.308q6.923-6.923 6.923-17.692T770-673.692l-68.615-68.616q-6.923-6.923-17.693-6.923-10.769 0-17.692 6.923L468.539-544.846ZM426.077-559l160.616 160.616-209.77 209.769q-19.384 19.385-45.231 19.385-25.846 0-45.23-19.385l-8.154-8.154-46 46h-110.77l101.385-101.385-6.616-6.615Q196.923-278.154 196.923-304t19.384-45.231L426.077-559Zm0 0 212.385-212.385q19.384-19.385 45.23-19.385 25.847 0 45.231 19.385l70.154 70.154q19.385 19.385 19.385 45.231t-19.385 45.231L586.693-398.384 426.077-559Z"/></svg>
		);
}

export function CommentDeleteBtnSvg(props) {
	return(
<svg  id="CommentDeleteBtnSvg"  viewBox="0 0 482.428 482.429" {...props}>
<g>
	<g>
		<path d="M381.163,57.799h-75.094C302.323,25.316,274.686,0,241.214,0c-33.471,0-61.104,25.315-64.85,57.799h-75.098
			c-30.39,0-55.111,24.728-55.111,55.117v2.828c0,23.223,14.46,43.1,34.83,51.199v260.369c0,30.39,24.724,55.117,55.112,55.117
			h210.236c30.389,0,55.111-24.729,55.111-55.117V166.944c20.369-8.1,34.83-27.977,34.83-51.199v-2.828
			C436.274,82.527,411.551,57.799,381.163,57.799z M241.214,26.139c19.037,0,34.927,13.645,38.443,31.66h-76.879
			C206.293,39.783,222.184,26.139,241.214,26.139z M375.305,427.312c0,15.978-13,28.979-28.973,28.979H136.096
			c-15.973,0-28.973-13.002-28.973-28.979V170.861h268.182V427.312z M410.135,115.744c0,15.978-13,28.979-28.973,28.979H101.266
			c-15.973,0-28.973-13.001-28.973-28.979v-2.828c0-15.978,13-28.979,28.973-28.979h279.897c15.973,0,28.973,13.001,28.973,28.979
			V115.744z"/>
		<path d="M171.144,422.863c7.218,0,13.069-5.853,13.069-13.068V262.641c0-7.216-5.852-13.07-13.069-13.07
			c-7.217,0-13.069,5.854-13.069,13.07v147.154C158.074,417.012,163.926,422.863,171.144,422.863z"/>
		<path d="M241.214,422.863c7.218,0,13.07-5.853,13.07-13.068V262.641c0-7.216-5.854-13.07-13.07-13.07
			c-7.217,0-13.069,5.854-13.069,13.07v147.154C228.145,417.012,233.996,422.863,241.214,422.863z"/>
		<path d="M311.284,422.863c7.217,0,13.068-5.853,13.068-13.068V262.641c0-7.216-5.852-13.07-13.068-13.07
			c-7.219,0-13.07,5.854-13.07,13.07v147.154C298.213,417.012,304.067,422.863,311.284,422.863z"/>
	</g>
</g>
</svg>);
}

export function CommentSvg(props){
	return(
		<svg id="CommentSvg"  viewBox="0 0 24 24" {...props}><g id="comment-Regular"><path id="comment-Regular-2" data-name="comment-Regular" class="cls-1" d="M17,3.25H7A4.756,4.756,0,0,0,2.25,8V21a.75.75,0,0,0,1.28.53l2.414-2.414a1.246,1.246,0,0,1,.885-.366H17A4.756,4.756,0,0,0,21.75,14V8A4.756,4.756,0,0,0,17,3.25ZM20.25,14A3.254,3.254,0,0,1,17,17.25H6.829a2.73,2.73,0,0,0-1.945.806L3.75,19.189V8A3.254,3.254,0,0,1,7,4.75H17A3.254,3.254,0,0,1,20.25,8Z"/></g></svg>
	);
}

export function LogowithTitle(props){
	return (
	<svg id="LogowithTitle" {...props} viewBox="0 0 3000 358.56"><path d="m177.31,285.79c-16.71,8.46-38.43,12.69-65.16,12.69-34.15,0-61.38-10.42-81.68-31.25C10.16,246.4,0,218.65,0,183.98s11.33-66.07,33.99-89.2c22.66-23.13,51.74-34.69,87.24-34.69,22.34,0,41.04,2.98,56.07,8.93v43.54c-15.66-9.08-33.42-13.63-53.26-13.63-23.29,0-41.95,7.57-56,22.71-14.04,15.14-21.07,34.88-21.07,59.21s6.68,43.02,20.05,57.33c13.37,14.31,31.17,21.46,53.41,21.46,20.99,0,39.94-5.01,56.86-15.04v41.19Z"/>
	<path d="m302.93,221.1h-87.87v-31.33h87.87v31.33Z"/>
	<path d="m396.6,63.84v230.56h-44.64V63.84h44.64Z"/>
	<path  d="m535.06,221.1h-87.87v-31.33h87.87v31.33Z"/>
	<path d="m770.01,63.84l-80.98,230.56h-50.28l-80.04-230.56h48.4l52.63,166.97c2.19,7,3.76,14.46,4.7,22.4h.94c.42-5.85,2.09-13.47,5.01-22.87l52.79-166.5h46.83Z"/>
	<path d="m882.94,221.1h-87.87v-31.33h87.87v31.33Z"/>
	<path d="m976.61,63.84v230.56h-44.64V63.84h44.64Z"/>
	<path d="m1115.07,221.1h-87.87v-31.33h87.87v31.33Z"/>
	<path d="m1296.14,294.41h-131.89V63.84h44.64v193.6h87.24v36.97Z"/>
	<path d="m1412.05,221.1h-87.87v-31.33h87.87v31.33Z"/>
	<path d="m1450.58,239.74c18.27,15.14,39.21,22.71,62.81,22.71,14.2,0,24.8-2.48,31.8-7.44,6.99-4.96,10.49-11.46,10.49-19.5,0-6.99-2.92-13.42-8.77-19.27-5.85-5.85-20-13.57-42.45-23.18-19.11-8.56-32.97-18.19-41.59-28.9-8.61-10.7-12.92-23.26-12.92-37.67,0-20.78,8.04-37.04,24.12-48.79,16.08-11.75,37.17-17.62,63.28-17.62,23.39,0,41.82,2.92,55.29,8.77v42.76c-14.93-10.23-32.95-15.35-54.04-15.35-12.43,0-22.48,2.35-30.15,7.05-7.68,4.7-11.51,11.18-11.51,19.42,0,6.58,2.66,12.59,7.99,18.01,5.33,5.43,17.8,12.48,37.44,21.15,22.56,9.82,38.22,20.13,46.99,30.94,8.77,10.81,13.16,23.73,13.16,38.77,0,21.72-7.75,38.3-23.26,49.73-15.51,11.43-37.25,17.15-65.24,17.15-26,0-47.15-4.33-63.44-13v-45.74Z"/>
	<path d="m1726.26,221.1h-87.87v-31.33h87.87v31.33Z"/><path d="m1938.18,285.79c-16.71,8.46-38.43,12.69-65.16,12.69-34.15,0-61.38-10.42-81.68-31.25-20.31-20.83-30.47-48.58-30.47-83.25s11.33-66.07,33.99-89.2c22.66-23.13,51.74-34.69,87.24-34.69,22.34,0,41.04,2.98,56.07,8.93v43.54c-15.66-9.08-33.42-13.63-53.26-13.63-23.29,0-41.95,7.57-56,22.71-14.04,15.14-21.07,34.88-21.07,59.21s6.68,43.02,20.05,57.33c13.37,14.31,31.17,21.46,53.41,21.46,20.99,0,39.94-5.01,56.86-15.04v41.19Z"/>
	<path d="m2063.8,221.1h-87.87v-31.33h87.87v31.33Z"/>
	<path d="m2098.42,182.1c0-36.23,10.42-65.63,31.25-88.18,20.83-22.56,48.53-33.83,83.09-33.83,32.79,0,59.18,10.94,79.18,32.81,19.99,21.88,30,49.94,30,84.19,0,36.13-10.31,65.39-30.94,87.79-20.62,22.4-47.91,33.6-81.84,33.6s-59.7-10.91-80.12-32.74c-20.42-21.82-30.62-49.71-30.62-83.64Zm46.99-2.51c0,23.39,5.77,42.58,17.31,57.56,11.54,14.98,27.23,22.48,47.07,22.48s36.57-7.13,48.01-21.38c11.43-14.25,17.15-33.6,17.15-58.03s-5.54-45.01-16.6-59.52c-11.07-14.51-26.73-21.77-46.99-21.77s-36.57,7.6-48.32,22.79c-11.75,15.19-17.62,34.49-17.62,57.88Z"/>
	<path d="m2445.52,221.1h-87.87v-31.33h87.87v31.33Z"/>
	<path d="m2494.7,294.41V63.84h76.44c81.66,0,122.49,37.49,122.49,112.46,0,35.71-11.51,64.32-34.54,85.84-23.03,21.51-52.65,32.27-88.89,32.27h-75.5Zm44.64-193.44v156.48h28.19c24.75,0,44.12-7.18,58.11-21.54,13.99-14.36,20.99-34.01,20.99-58.97s-7-43.33-20.99-56.39c-13.99-13.05-33.26-19.58-57.8-19.58h-28.51Z"/>
	<path  d="m2817.37,221.1h-87.87v-31.33h87.87v31.33Z"/>
	<path  d="m3000,294.41h-133.45V63.84h128.28v37.12h-83.64v58.74h77.69v36.97h-77.69v60.77h88.81v36.97Z"/>
	</svg>
	);
}

