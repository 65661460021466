import React,{useState, useEffect} from 'react';
import './DarkModeToggle.css';


export function ApplyTheme(mode)
{
if(mode)
  document.documentElement.className = 'light';
else
  document.documentElement.className = 'dark';
}
// restrictive dark mode will not apply for :root
export default function DarkModeToggle() {
    const [isSunMode, setIsSunMode] = useState(
      localStorage.getItem('islightmode') !== null 
        ? JSON.parse(localStorage.getItem('islightmode')) 
        : true);
    useEffect(()=>{
      setIsSunMode(localStorage.getItem('islightmode')!=null ? JSON.parse(localStorage.getItem('islightmode')) : true);
   },[]);

   useEffect(()=>{
    ApplyTheme(isSunMode);
  },[isSunMode]);

   function toggleDarkMode(mode)
    {
      localStorage.setItem('islightmode', mode);
        setIsSunMode(mode);
    }

  return (
<div className="DarkToggle">
    <button className="theme-btn" onClick={() => {toggleDarkMode(!isSunMode)}} title={isSunMode ? "Light mode" : "Dark mode"}>
      <div className="DarkToggleSvg" tabindex="0" alt={isSunMode ? "sun" : "moon"}>
      {isSunMode ?  <svg fill="#000000" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"  enable-background="new 0 0 512 512">
  <g>
    <g>
      <path d="m256,105.5c-83.9,0-152.2,68.3-152.2,152.2 0,83.9 68.3,152.2 152.2,152.2 83.9,0 152.2-68.3 152.2-152.2 0-84-68.3-152.2-152.2-152.2zm0,263.5c-61.4,0-111.4-50-111.4-111.4 0-61.4 50-111.4 111.4-111.4 61.4,0 111.4,50 111.4,111.4 0,61.4-50,111.4-111.4,111.4z"/>
      <path d="m256,74.8c11.3,0 20.4-9.1 20.4-20.4v-23c0-11.3-9.1-20.4-20.4-20.4-11.3,0-20.4,9.1-20.4,20.4v23c2.84217e-14,11.3 9.1,20.4 20.4,20.4z"/>
      <path d="m256,437.2c-11.3,0-20.4,9.1-20.4,20.4v22.9c0,11.3 9.1,20.4 20.4,20.4 11.3,0 20.4-9.1 20.4-20.4v-22.9c0-11.2-9.1-20.4-20.4-20.4z"/>
      <path d="m480.6,235.6h-23c-11.3,0-20.4,9.1-20.4,20.4 0,11.3 9.1,20.4 20.4,20.4h23c11.3,0 20.4-9.1 20.4-20.4 0-11.3-9.1-20.4-20.4-20.4z"/>
      <path d="m54.4,235.6h-23c-11.3,0-20.4,9.1-20.4,20.4 0,11.3 9.1,20.4 20.4,20.4h22.9c11.3,0 20.4-9.1 20.4-20.4 0.1-11.3-9.1-20.4-20.3-20.4z"/>
      <path d="M400.4,82.8L384.1,99c-8,8-8,20.9,0,28.9s20.9,8,28.9,0l16.2-16.2c8-8,8-20.9,0-28.9S408.3,74.8,400.4,82.8z"/>
      <path d="m99,384.1l-16.2,16.2c-8,8-8,20.9 0,28.9 8,8 20.9,8 28.9,0l16.2-16.2c8-8 8-20.9 0-28.9s-20.9-7.9-28.9,0z"/>
      <path d="m413,384.1c-8-8-20.9-8-28.9,0-8,8-8,20.9 0,28.9l16.2,16.2c8,8 20.9,8 28.9,0 8-8 8-20.9 0-28.9l-16.2-16.2z"/>
      <path d="m99,127.9c8,8 20.9,8 28.9,0 8-8 8-20.9 0-28.9l-16.2-16.2c-8-8-20.9-8-28.9,0-8,8-8,20.9 0,28.9l16.2,16.2z"/>
    </g>
  </g>
</svg>
       : <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="M483-172q-128.333 0-218.167-89.833Q175-351.667 175-480q0-113 71.5-197.5T425-783q-14 28-22 59t-8 64q0 111.667 78.167 189.833Q551.333-392 663-392q33 0 64-8t58-22q-20 107-104.5 178.5T483-172Zm0-28q88 0 158-48.5T743-375q-20 5-40 8t-40 3q-123 0-209.5-86.5T367-660q0-20 3-40t8-40q-78 32-126.5 102T203-480q0 116 82 198t198 82Zm-10-270Z"/></svg>
       }
      </div>
    </button>
  </div>
    )
}
