import './CommentBox.css'
import {useState, useEffect} from "react";
import React, { useRef } from 'react'
import { CommentDeleteBtnSvg} from '../config.js'



export default function CommentBox({rangeofSelection, reference, OnDeleteComment, onChangeComment, prevcomment})
{
  const [comment, setComment] = useState(prevcomment);
  const maxRows = 17;

  const MAX_CHARS = 500;
  const commentboxref = useRef(null);
  const textarearef = useRef(null);

  useEffect(() => {
        var clientRect = rangeofSelection.getBoundingClientRect();
        var clickX = clientRect.x + window.scrollX + (clientRect.width/2);
        var clickY = clientRect.y + window.scrollY + (clientRect.height)+10;
        commentboxref.current.style.left = clickX + 'px';
        commentboxref.current.style.top = clickY+'px';
        commentboxref.current.style.position = 'absolute';
        commentboxref.current.style.display = 'block';
  },[rangeofSelection])

  useEffect(()=>{
    const adjustTextAreaHeight = () => {
      const lineHeight = parseInt(window.getComputedStyle(textarearef.current).lineHeight);
      const maxHeight = lineHeight * maxRows;
      textarearef.current.style.height = 'auto'; // Reset height to properly read scrollHeight
      const scrollHeight = textarearef.current.scrollHeight;

      if (scrollHeight > maxHeight) {
        textarearef.current.style.height = `${maxHeight}px`;
        textarearef.current.style.overflow = 'auto';  // Enable scrollbar when max height is reached
      } else {
        textarearef.current.style.height = `${scrollHeight}px`;
        textarearef.current.style.overflow = 'hidden';  // Hide scrollbar when content is within limits
      }
    };

    adjustTextAreaHeight();
    // Add event listener to resize text area on input
    textarearef.current.addEventListener('input', adjustTextAreaHeight);

    return () => {
      // Clean up event listener
      if(textarearef.current){
        textarearef.current.removeEventListener('input', adjustTextAreaHeight);
      }
    };
  }, [])

    function onChangeCommentField(event)
    {
      setComment(event.target.value);
      onChangeComment(event.target.value, reference);
    }

    function onDeleteCommentField(ref)
    {

        OnDeleteComment(ref);
    }

    return(
        <div className="comment-box" ref={commentboxref}>
        <div className="header">
          <span>Comment</span>
          <button className="deleteCommentBtn" onMouseDown={(event) => {event.stopPropagation(); onDeleteCommentField(reference)}}>
            <CommentDeleteBtnSvg />
          </button>
        </div>
        { <div className='textareaWithCount'><textarea  ref={textarearef}
        rows="3"
        className='commentarea'
        wrap="hard" cols="30" maxlength={MAX_CHARS} onChange={onChangeCommentField}
      >{comment}</textarea>
      <div className='updateCommentLen'>
        {comment.length}/{MAX_CHARS}
        </div>
      </div>}
      </div>
    );

}