
import ContentPage from './ContentPage.js';
import './ContentPageWithBackButton.css';


export default function ContentPageWithBackButton({ backToLogin, subject, tK, topic, type, saveState, onBackButton, TopicsList = null, LoadContentPageForNextPrev = null, revisions=null, onRevisionsUpdate= null}) {
    return (
        <div>
            <div className="GoBackArrow" onClick={onBackButton}>
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24" className="SearchBackArrow">
                <path d="M560-276 356-480l204-204 20 20-184 184 184 184-20 20Z"/>
                </svg>
            </div>
            <ContentPage backToLogin={backToLogin} tK={tK} topic={topic} type={type} subject={subject} saveState={saveState} TopicsList={TopicsList} LoadContentPage={LoadContentPageForNextPrev} revisions={revisions} onRevisionsUpdate={onRevisionsUpdate}/>
        </div>
    )
 }