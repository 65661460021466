import './ForgotPassword.css';
import { useSearchParams } from "react-router-dom";
import Form from "react-bootstrap/Form";
import React, { useState} from "react";
import {ResetPasswordUrl, ApiKey} from '../config.js';
import axios from 'axios';


export default function ForgotPassword()
{
  const [searchParams] = useSearchParams();
  const hash =  searchParams.get('hash');
  const email = searchParams.get('email');
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [errormsg, setErrormsg] = useState({msg: "", isError: 1});


  if(!hash || !email)
    return (
      <div className='ForgotPasswordPage'>
        <p className='ForgotPasswordPageh1'>Error please try again</p>
    </div>
    );
  function handleSubmit(event) {
    event.preventDefault();
    var req = {
      hash: hash,
      email: email.toLowerCase(),
      password: password
    };
    const headers = { 
      'x-api-key': ApiKey
    };
    
        axios.post(ResetPasswordUrl, req, { headers:headers}).then(resp=>{setMsgFlash("Password Changed Successfully. Redirecting", 0); window.location.href = '/'}).catch(err => {setMsgFlash(err.response.data.message);});

  };
  function setMsgFlash(error, iserror=1)
  {

    setErrormsg({msg: error, isError: iserror});
    setTimeout(() => {
      setErrormsg({msg: "", isError: 1});
    }, 3000);
  }

  const validatePassword = (passwordtest) => {
    // Check the minimum length requirement
    if (passwordtest.length < 8) {
      return "Password must be at least 8 characters long.";
    }
  
    // Check for at least one uppercase letter
    if (!/[A-Z]/.test(passwordtest)) {
      return "Password must contain at least one uppercase letter.";
    }
  
    // Check for at least one lowercase letter
    if (!/[a-z]/.test(passwordtest)) {
      return "Password must contain at least one lowercase letter.";
    }
  
    // Check for at least one numeric digit
    if (!/[0-9]/.test(passwordtest)) {
      return "Password must contain at least one numeric digit.";
    }
  
    // Check for at least one special character
    if (!/[!@#$%^&*()|}{}":;'><.,]/.test(passwordtest)) {
      return "Password must contain at least one special character (!, @, #, etc).";
    }
  
    // All requirements have been met
    return "";
  }
  function validateForm() {
    return (password2===password && password.length >= 7);
  }
  return (<div className='ForgotPasswordPage'>
      <Form.Group className="Formgroup">
      <form className="CCform" onSubmit={handleSubmit}>
      <div className={errormsg.msg.length===0 ? "ErrorDisplay" : (errormsg.isError===1 ? "ErrorDisplay ErrorDisplayAnimate" : "ErrorDisplayNoError")}>
          {errormsg.msg}
      </div>
      <div className="ForgotPasswordForm">
        <h2 className="FPheading2" > Enter New Civilscode Password </h2>
        <Form.Control placeholder="Password"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            onBlur={(e) => {setMsgFlash(validatePassword(e.target.value))}}
          />
          <Form.Control placeholder="Confirm Password"
            type="password"
            onChange={(e) => setPassword2(e.target.value)}
          />
        <button className="Changepwdbtn" type="submit" disabled={!validateForm()}>Change Password</button>
      </div>
      </form>
      </Form.Group >
  </div>);

}
