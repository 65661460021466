import React from "react";

import './ModalDiag.css'
import Spinner from "./Spinner";

function ModalDiag({title, body, closeModal, onOkay, isLoading, onCancel})
{
    return(
        <div className="ModalDiagBackground">
            <div className="ModalDiag">
                <div className="ModalDiagTitleCloseButton">
                <button onClick={closeModal}>X</button>
                </div>
                <div className="ModalDiagTitle">
                    <h1 className="ModalDiagTitleH1">{title}</h1>
                </div>
                {isLoading===true  ? (<div className="ModalDiagBody"> <Spinner /> </div>): (<div className="ModalDiagBody" dangerouslySetInnerHTML={{__html: body}}></div>)}
                <div className="ModalDiagFooter">
                    <button onClick={() => onOkay()}>Okay</button>
                    {onCancel && <button onClick={closeModal}>Cancel</button>}
                </div>

            </div>
        </div>
    )
}

export default ModalDiag;