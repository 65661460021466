import React, { useEffect } from 'react';

const NoPrintPage = () => {
  useEffect(() => {
    const mediaQueryList = window.matchMedia('print');
    let styleTag;

    function changePrintStyles(mql) {
      if(mql.matches) {
        styleTag = document.createElement('style');
        styleTag.innerHTML = 'body * { display: none; }';
        document.head.appendChild(styleTag);
      } else {
        styleTag && document.head.removeChild(styleTag);
      }
    }

    // Using addEventListener instead of deprecated addListener
    mediaQueryList.addEventListener('change', changePrintStyles);

    return () => {
      // Using removeEventListener instead of deprecated removeListener
      mediaQueryList.removeEventListener('change', changePrintStyles);
    }
  }, []);

  return <div></div>
}

export default NoPrintPage;