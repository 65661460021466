import React from 'react';
import './Spinner.css';

// todo: fix app.css 100% for content page so wholepage patch can be removed.
export default function Spinner({wholepage}) {
  return (
    <div className={wholepage===true ? "fullpage" :"nonfullpage"}>
      <div className={wholepage===true ? "WholePageSpinner" :"Spinner"}></div>
    </div>
  )
}
