import React from 'react'
import Sidebar from './Sidebar.js';
import SyllabusPage from './SyllabusPage.js';
import ContentPageWithBackButton from './ContentPageWithBackButton.js';
import SearchResultsControl from './SearchResults.js';
import LogoAndEaseMenu, { EaseMenuDiag } from './LogoAndEaseMenu.js';
import './MainPage.css';
import {useState, useEffect} from "react";


export default function MainPage({backToLogin, subjects, opensubject, showIconizedMenu, revisions, onRevisionsUpdate})
{
  const [openMenu, setOpenMenu] = useState(false);
  const [saveUserState, setsaveUserState] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [prevMenuPage, setPrevMenuPage] = useState(null);
  const [easeMenu, setEaseMenu] = useState(false);


  const [page, setPage] = useState({
  SubjectPage: {
    loadSubjectPage: false, 
    selectedSub: null
  }, 
  ContentPage : {
   loadContentPage: false, 
   TK: null, 
   Topic:null,
   Type:null},
   SearchResultsPage: 
   {
    loadSearchResultsPage: false,
    SearchResults : null
   }
  }
  );
  useEffect(() => {
    onMenuItemClick(opensubject);
  }, [opensubject])
  // Doesn't work for safari.

  /*const handleScroll = () => {
    setEaseMenu(false);
  };*/
   // Every 10 min we will try to save state.
   useEffect(() => {
    onMenuItemClick(opensubject);
    // Doesn't work for safari.
    //window.addEventListener('scroll', handleScroll);
 
    const timer = setInterval(() => {
      setsaveUserState(saveUserState => !saveUserState);
    }, 10*60*1000);


    return () => {
      clearInterval(timer)
      //window.removeEventListener('scroll', handleScroll);
    };

   }, [])
   
   
  function onMenuItemClick(selSubject)
  {
    setPage({ 
    SubjectPage : 
      {
        loadSubjectPage: true, 
        selectedSub: selSubject
      },
      ContentPage : 
      {
        loadContentPage: false, 
        TK: null, 
        Topic:null,
        Type: null,
      },
      SearchResultsPage: 
      {
        loadSearchResultsPage: false,
        SearchResults : null
      }
    });
  }
  function cacheCurrentMenuPage()
  {
    const scrollPosition = window.scrollY;
    setScrollPosition(scrollPosition);
    setPrevMenuPage(page);
  }
  function restorePrevMenuPageAndState()
  {
    setPage(prevMenuPage);
    setTimeout(() => {
      window.scrollTo(0, scrollPosition);
   }, 30);
  }
  function onLoadContentPage(subject, TK, Topic, type, TopicListForNxtAndPrev = null)
  {
    // Only cache menu
    if(page.SubjectPage.loadSubjectPage===true)
      cacheCurrentMenuPage();
    setPage({ 
      SubjectPage : 
        {
          loadSubjectPage: false, 
          selectedSub: subject
        },
        ContentPage : 
        {
          loadContentPage: true, 
          TK: TK, 
          Topic:Topic,
          Type: type,
          TopicListForNxtAndPrev: TopicListForNxtAndPrev
        },
        SearchResultsPage: 
        {
         loadSearchResultsPage: false,
         SearchResults : null
        }
      });
  }
  function onLoadContentPageFromEaseMenu(subject, TK, Topic, type)
  {
    onLoadContentPage(subject, TK, Topic, type);
    ToggleEaseMenu();
  }
  function SearchResults(results)
  {
    setEaseMenu(false);
    setPage({
      SubjectPage: {
        loadSubjectPage: false, 
        selectedSub: null
      }, 
      ContentPage : {
       loadContentPage: false, 
       TK: null, 
       Topic:null,
       Type:null},
       SearchResultsPage: 
       {
        loadSearchResultsPage: true,
        SearchResults : results
       }});
  }
 function onContentPageBackButton()
 {
  restorePrevMenuPageAndState();
 }

 function ToggleEaseMenu()
 {
  setEaseMenu(!easeMenu);
 }

  //todo:: props.
  return (
    <div onContextMenu={(e) => e.preventDefault()}>
      <LogoAndEaseMenu OnClickFunction={showIconizedMenu} onClickEaseMenu={ToggleEaseMenu} />
      {easeMenu && <EaseMenuDiag subjects={subjects} OnSearchResults={SearchResults} backToLogin={backToLogin} loadContentPage={onLoadContentPageFromEaseMenu} revisions={revisions} onRevisionsUpdate={onRevisionsUpdate}/>}
      <div className='OtherThanSideBar' onClick={() => setEaseMenu(false)} onTouchStart={() => setEaseMenu(false)}>
        {page.SearchResultsPage.loadSearchResultsPage && <SearchResultsControl  backToLogin={backToLogin} subjects={subjects} Results={page.SearchResultsPage.SearchResults} saveState={saveUserState} revisions={revisions} onRevisionsUpdate={onRevisionsUpdate}/>}
        {page.SubjectPage.loadSubjectPage && <div className='MainPage'>
       <SyllabusPage backToLogin={backToLogin} subject={page.SubjectPage.selectedSub} loadContentPage={onLoadContentPage} revisions={revisions.filter(revision => revision.Navigation.startsWith(page.SubjectPage.selectedSub.SubjectName))}/>
      </div>}
      {page.ContentPage &&  page.ContentPage.loadContentPage && <ContentPageWithBackButton key={JSON.stringify(page)} backToLogin={backToLogin} tK={page.ContentPage.TK} topic={page.ContentPage.Topic} type={page.ContentPage.Type} subject={page.SubjectPage.selectedSub} saveState={saveUserState} TopicsList={page.ContentPage.TopicListForNxtAndPrev} LoadContentPageForNextPrev={onLoadContentPage} onBackButton={onContentPageBackButton} revisions={revisions} onRevisionsUpdate={onRevisionsUpdate}/>}
      </div>

    </div>
  )
}