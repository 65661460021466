import React from 'react';
import ReactDOM from 'react-dom';
import App from "./App";
import './index.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";

import ForgotPassword from './Components/ForgotPassword.js'


ReactDOM.render(
  <React.StrictMode>
  <BrowserRouter>
    <Routes>
      <Route path="/" element= {
      <React.Fragment>
        <App/>
      </React.Fragment>}/>
      <Route path="/resetpassword" element={<ForgotPassword />}>
      </Route>
    </Routes>
  </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);

