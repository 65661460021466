import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './RevisionSchedulerList.css'
import { ApiKey, dataUrl} from '../config.js'
import Spinner from './Spinner.js';

import ModalDiag from "./ModalDiag";



const RevisionSchedulerList = ({subjects, backToLogin, loadContentPage, revisions, onRevisionsUpdate}) => {
  const warningTitle = "Warning";
  const warningBody = "All your revisions scheduled will be cleared. It is like you are starting fresh.\n";
  const afterDeleteTitle = "Info";
  const afterDeleteBody = "Revisions have been successfully deleted. Starting afresh, all the best.";
  const problemDelete = "Failed to delete, try again after sometime";
  const [isBoxToggle, setBoxToggle] = useState(false);
  const [activeTab, setActiveTab] = useState('today');
  const [isLoading, setIsLoading] = useState(true);
  const [list, setList] = useState(null);
  const modalDataInitial = {
    showWarning: false,
    title: warningTitle,
    body: warningBody,
    isLoading: false, 
    onOkay: () => ClearRevisionSchedules()
  }
  const [modalData, setModalData] = useState(modalDataInitial);
  useEffect(() => {
    if(!isBoxToggle)
      return;
    if(revisions!=null)
    {
      const today = new Date();
      today.setHours(0,0,0,0); // setting today's date and ignoring the time

    const filteredRevisions = revisions.filter(revision => {
            const revisionDate = new Date(revision.NextRevisionDate);
            revisionDate.setHours(0,0,0,0); // setting revision date and ignoring the time
  
              if(activeTab!=='today') {
                return revisionDate.getTime() < today.getTime();
            
              } else {
                return revisionDate.getTime() === today.getTime();
              }
            });
      const groupedRevisions = filteredRevisions.reduce((groups, record) => {
        const splitPage = record.Navigation.split("^");
        if (!groups[splitPage[0]]) {
          groups[splitPage[0]] = [];
        }
        groups[splitPage[0]].push([splitPage[1], splitPage[2], splitPage[3]]);
        return groups;
      },{})
      setList(groupedRevisions);
      setIsLoading(false);
      return;
    }
    var req = {
      email: localStorage.getItem('user'),
      method: 'GetRevisions',
      params: {
        IsBacklog: activeTab==='today' ? 'N' : 'Y'
      }
    };
    const headers = { 
      'x-api-key': ApiKey
    };
    axios.post(dataUrl, req, { withCredentials: true, headers:headers}).then(resp => {
      setIsLoading(false);
      const groupedRevisions = resp.data.reduce((groups, record) => {
        if (!groups[record.result.subject]) {
          groups[record.result.subject] = [];
        }
        groups[record.result.subject].push([record.result.TK, record.result.Topic, record.result.Type]);
        return groups;
      }, {});
      setList(groupedRevisions);
      console.log(resp.data);
    }).catch(err => {console.log(err);backToLogin();});

  }, [activeTab, isBoxToggle]);
  
  function ShowWarning()
  {
    setModalData(prevState => ({
      ...prevState,
      showWarning: true,
      title: warningTitle,
      body: warningBody,
      isLoading: false, 
      onOkay: () => ClearRevisionSchedules()
    }));
  }
  function CloseModal()
{
  setModalData(modalDataInitial);
}
function ClearRevisionSchedules()
{
  setModalData(prevState => ({
    ...prevState,
    isLoading: true
  }));
  var req = {
    email: localStorage.getItem('user'),
    method: 'ClearRevisionSchedules'
  };
  const headers = { 
    'x-api-key': ApiKey
  };
  axios.post(dataUrl, req, { withCredentials: true, headers:headers}).then(resp => {
    onRevisionsUpdate([]);
    setModalData(prevState => ({
      ...prevState,
      title: afterDeleteTitle,
      body: afterDeleteBody,
      isLoading: false,
      onOkay: ()=>CloseModal()
    }));
  }).catch(err => {setModalData(prevState => ({
    ...prevState,
    title: afterDeleteTitle,
    body: problemDelete,
    isLoading: false,
    onOkay: ()=>CloseModal()
  }));
  console.log(err);backToLogin();});
}
  return (
    <div className="RevisionSchedulerList">
      <button className={'RevisionSchedulerListButton '+ (isBoxToggle ? 'RevisionSchedulerListButtonSelection'  : '')} onClick={() => setBoxToggle(!isBoxToggle)}>
      Scheduled Revisions
      </button>

      {isBoxToggle && (
        <div className="RevisionSchedulerListContent">
          <div className="RevisionSchedulerListButtons">
            <button className={ (activeTab === 'today' ? 'RevisionSchedulerListButtonSelection'  : '')} onClick={() => setActiveTab('today')}>Today</button>
            <button className={(activeTab === 'backlog' ? 'RevisionSchedulerListButtonSelection'  : '')} onClick={() => setActiveTab('backlog')}>Backlog</button>
            <button className='RevisionSchedulerListButton ClearBacklog' onClick={() => ShowWarning()}>Reset Revisions</button>
          </div>
          {isLoading ? <Spinner wholepage={false}/> : (
            <div className="RevisionSchedulerListContentOnly">
            {list && Object.keys(list).length>0 ? Object.keys(list).map(subject => (
              <div key={subject}>
              <h1>{subjects.filter(sub => sub.SubjectName===subject)[0].DisplayName}</h1>
                {list[subject].map((TKTopicAndType, index) => (<li className="TopicList" key={index} onClick={()=> loadContentPage(subject, TKTopicAndType[0], TKTopicAndType[1], TKTopicAndType[2])}>{TKTopicAndType[1]}</li>))}
              </div>
              )
            ) : <p>No revision tasks found</p> }
             </div>)
          }
        { activeTab==='backlog' && Object.keys(list).length>0  && <div className='RevisionRescheduleHint'>*You can reschedule by opening the topic</div> }
        </div>
      )}
      {modalData.showWarning===true && (<div className='WarningDiag'><ModalDiag  title={modalData.title} body={modalData.body} closeModal={CloseModal} onOkay={modalData.onOkay} isLoading={modalData.isLoadingModal} onCancel={CloseModal}/></div>)}

    </div>
  );
};

export default RevisionSchedulerList;