import React from 'react'
import {ApiKey, razorpayURL, RazorpayAPIkey, paymentUrl, paymentverificationUrl} from '../config.js'
import axios from 'axios';
import './Subscribe.css'
export default function Subscribe({msg, backToLogin}) {
  let data;
  function PaymentSuccessHandler(response){
    /*alert(response.razorpay_payment_id);
    alert(response.razorpay_order_id);
    alert(response.razorpay_signature);*/
    response['Callfrom'] = 'ClientHandler';
    response['Username'] = localStorage.getItem('user');
    response['OrderId'] = data.order_id;
    axios.post(paymentverificationUrl, response).then(resp => {
      // todo:: use modal component present here. 
     // https://github.com/machadop1407/React-Modal-Tutorial
     console.log(resp);
     alert(resp.data.message);
     backToLogin();
   }).catch(err => {
     alert(err);
     backToLogin();
    });
  }
  function PaymentFailureHandler(response)
  {
    /*alert(response.error.code);
    alert(response.error.description);
    alert(response.error.source);
    alert(response.error.step);
    alert(response.error.reason);
    alert(response.error.metadata.order_id);
    alert(response.error.metadata.payment_id);*/
    alert(response.error.code +" : " + response.error.description);
  }

  async function displayRazorPay(e)
    {
      e.preventDefault();
      const razorpayScript = await loadRazorPay();
      if(!razorpayScript)
        {
        alert('Failed to load payment gateway');
        return;
        }
        //Todo:: custom buy option.
        var req = {
          email: localStorage.getItem('user')
        };
        
        const headers = { 
          'x-api-key': ApiKey
        };
        axios.post(paymentUrl, req, { withCredentials: true, headers:headers}).then(resp => {
           // todo:: use modal component present here. 
          // https://github.com/machadop1407/React-Modal-Tutorial
          data = resp.data;
          var options = {
            "key": RazorpayAPIkey, 
            "amount": data.amount, 
            "currency": data.currency,
            "name": "Civilscode",
            "description": "Full Access",
            "image": process.env.PUBLIC_URL + '/logo.svg',
            "order_id": data.order_id, 
            "handler": PaymentSuccessHandler,
            "prefill": {
                "email": localStorage.getItem['user']
              },
            "theme": {
                "color": "#4cbf6a"
            }
        }; 
        var rzpl = new window.Razorpay(options);
        rzpl.on('payment.failed', PaymentFailureHandler);
        rzpl.open();
          alert("Please make a note of this order id: " + data.order_id);
        }).catch(err => { alert(err.response.data.message); });
        
    }
  function loadRazorPay() {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = razorpayURL;
      script.async = true;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      }
      document.body.appendChild(script);
    });
    }
  return (
    <div className='Subscribe'>
      <span>
      {msg}
      <form className='SubscribeForm' onSubmit={displayRazorPay}>
        <button className="SubscribeBtn" type="submit">{"Subscribe"}</button>
      </form>
      </span>
 
      </div>
  )
}
